import Vue from 'vue'
import Vuex from 'vuex'

import { MenuTree } from '@/menu/Menu';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        authorities: MenuTree.emptyMenuCode(),
    },
    mutations: {
        setAuthorities(state, auth) {
            if (auth) {
                for (var key in auth) {
                    state.authorities[key] = auth[key];
                }
            }
        }
    },
    actions: {
    },
    modules: {
    }
})
