
const Locale = {
    ZH_CN: {
        locale: 'zh_cn',
        name: '简体中文',
    },
    EN: {
        locale: 'en',
        name: 'English',
    },
    instance() {
        return [this.ZH_CN, this.EN];
    },
    name(locale) {
        for(var i in Locale.instance()) {
            if (Locale.instance()[i].locale == locale) {
                return Locale.instance()[i].name;
            }
        }
        return '';
    }
}


export {Locale}