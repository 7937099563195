import Storage from "@/storage/Storage"
import Request from "@/request/Request"
import Service from "@/request/Service"
import { MenuTree } from "@/menu/Menu"


const CommonMixin = {

	data: function() {
		return {
			// Enumeration,
			Storage,
			Request,
			Service,
			Authorities: MenuTree.emptyMenuCode(),
		}
	},
	filters: {
		amountFormat: function(value, symbol = false) {
			if (!value) return '0';
			value = value.toString();
			var radio = Math.pow(10, 0);
			var result = parseFloat(value);
			if (isNaN(result)) {
				return result;
			}
			result = Math.round(value * radio) / radio;
			if (symbol) {
				return (result > 0 ? '+' : '') + result.toLocaleString();
			} else {
				return result.toLocaleString();
			}
		}
	},
	methods: {

		decimalFixed(num, rate) {
			var radio = Math.pow(10, rate);
			var result = parseFloat(num);
			if (isNaN(result)) {
				return result;
			}
			result = Math.round(num * radio) / radio;
			return result;
		},
		hasPermission(code) {
			return this.$store.state.authorities && this.$store.state.authorities[code];
		}

	},
	mounted() {
		// this.$EventBus.$on("authoritiesChange", (data)=>{
		// 	console.log(this.Authorities);
		// 	this.Authorities['agent_list_add'] = "aaa";
		// 	// Object.assign(this.Authorities, data);
		// 	console.log(this.Authorities);
		// })
	}
}


export default CommonMixin